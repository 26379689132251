import { NgClass } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, InjectionToken, inject, Injector, signal, computed, EventEmitter, ChangeDetectorRef, untracked, effect, Component, ChangeDetectionStrategy, Output, Input } from '@angular/core';
import { LfIconComponent } from '@leafio/ui/icons';
function LfPaginator_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate4(" ", ctx_r0.intl.rows, " ", ctx_r0.pageInfo(), " ", ctx_r0.intl.of, " ", ctx_r0.totalCount(), " ");
  }
}
const _c0 = a0 => ({
  disabled: a0
});
function LfPaginator_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 8);
    i0.ɵɵlistener("click", function LfPaginator_Conditional_3_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(!ctx_r6.prevButtonDisabled() && ctx_r6.setPage(0));
    });
    i0.ɵɵelement(1, "lf-icon", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c0, ctx_r1.prevButtonDisabled()));
  }
}
function LfPaginator_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 10);
    i0.ɵɵlistener("click", function LfPaginator_Conditional_4_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r9);
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(!ctx_r8.prevButtonDisabled() && ctx_r8.setPage(ctx_r8.pageIndex() - 1));
    });
    i0.ɵɵelement(1, "lf-icon", 11);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c0, ctx_r2.prevButtonDisabled()));
  }
}
function LfPaginator_For_6_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r19 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 13);
    i0.ɵɵlistener("click", function LfPaginator_For_6_Conditional_0_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r19);
      const i_r10 = i0.ɵɵnextContext().$implicit;
      const ctx_r17 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r17.setPage(i_r10));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const i_r10 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i_r10 + 1);
  }
}
function LfPaginator_For_6_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r23 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 14);
    i0.ɵɵlistener("click", function LfPaginator_For_6_Conditional_1_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r23);
      const i_r10 = i0.ɵɵnextContext().$implicit;
      const ctx_r21 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r21.setPage(i_r10));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const i_r10 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i_r10 + 1);
  }
}
function LfPaginator_For_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, LfPaginator_For_6_Conditional_0_Template, 2, 1, "div", 12)(1, LfPaginator_For_6_Conditional_1_Template, 2, 1);
  }
  if (rf & 2) {
    const i_r10 = ctx.$implicit;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵconditional(0, i_r10 === ctx_r3.pageIndex() ? 0 : 1);
  }
}
function LfPaginator_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r26 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 15);
    i0.ɵɵlistener("click", function LfPaginator_Conditional_7_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r26);
      const ctx_r25 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(!ctx_r25.nextButtonDisabled() && ctx_r25.setPage(ctx_r25.pageIndex() + 1));
    });
    i0.ɵɵelement(1, "lf-icon", 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c0, ctx_r4.nextButtonDisabled()));
  }
}
function LfPaginator_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r28 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 17);
    i0.ɵɵlistener("click", function LfPaginator_Conditional_8_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r28);
      const ctx_r27 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(!ctx_r27.nextButtonDisabled() && ctx_r27.setPage(ctx_r27.lastPageIndex()));
    });
    i0.ɵɵelement(1, "lf-icon", 18);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(1, _c0, ctx_r5.nextButtonDisabled()));
  }
}
class LfPaginatorIntlService {
  constructor() {
    this.rows = 'Rows';
    this.of = 'of';
  }
  static {
    this.ɵfac = function LfPaginatorIntlService_Factory(t) {
      return new (t || LfPaginatorIntlService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LfPaginatorIntlService,
      factory: LfPaginatorIntlService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfPaginatorIntlService, [{
    type: Injectable
  }], null, null);
})();
const LF_DEFAULT_PAGINATOR_CONFIG = {
  hidePageInfo: false,
  pageSize: 10,
  showFirstLastButtons: true,
  showPrevNextButtons: true,
  buttonsLength: 5,
  zeroBasedIndexing: true
};
const LF_PAGINATOR_CONFIG = new InjectionToken('LF_PAGINATOR_CONFIG', {
  factory: () => LF_DEFAULT_PAGINATOR_CONFIG
});
class LfDefaultPaginatorIntl {
  constructor() {
    this.rows = 'Rows';
    this.of = 'of';
  }
}
class LfPaginator {
  set length(length) {
    this.totalCount.set(length);
  }
  set currentPage(index) {
    this.pageIndex.set(this.config.zeroBasedIndexing ? index : index - 1);
  }
  constructor() {
    this.config = {
      ...LF_DEFAULT_PAGINATOR_CONFIG,
      ...inject(LF_PAGINATOR_CONFIG)
    };
    this.intl = inject(Injector).get(LfPaginatorIntlService, new LfDefaultPaginatorIntl());
    this.totalCount = signal(0);
    this.pageSize = signal(this.config.pageSize);
    this.pageIndex = signal(0);
    this.buttonsLength = computed(() => Math.min(this.pageList().length, this.config.buttonsLength));
    this.totalPages = computed(() => Math.ceil(this.totalCount() / this.pageSize()));
    this.pageInfo = computed(() => `${this.pageIndex() * this.pageSize() + 1} - ${Math.min(this.pageIndex() * this.pageSize() + this.pageSize(), this.totalCount())}`);
    this.pageList = computed(() => {
      const list = [];
      let pageIndex = 0;
      list.push(pageIndex);
      while (this.totalCount() - pageIndex * this.pageSize() > this.pageSize()) {
        pageIndex += 1;
        list.push(pageIndex);
      }
      return list;
    });
    this.buttonsList = computed(() => {
      let start = Math.max(this.pageIndex() - Math.floor(this.buttonsLength() / 2), 0);
      while (start > this.pageList().length - this.buttonsLength()) {
        start -= 1;
      }
      const end = start + this.buttonsLength();
      return this.pageList().slice(start, end);
    });
    this.prevButtonDisabled = computed(() => this.pageIndex() === 0);
    this.nextButtonDisabled = computed(() => this.pageIndex() === this.lastPageIndex());
    this.lastPageIndex = computed(() => this.pageList().at(-1));
    this.emitEvent = signal(false);
    this.pageChange = new EventEmitter(true);
    this.cdr = inject(ChangeDetectorRef);
    this.effectRef = effect(() => {
      if (this.emitEvent()) {
        const index = this.pageIndex();
        this.pageChange.emit({
          pageIndex: this.config.zeroBasedIndexing ? index : index + 1,
          pageSize: untracked(this.pageSize),
          length: untracked(this.totalCount)
        });
      }
    });
  }
  ngOnDestroy() {
    this.effectRef.destroy();
  }
  markAsDirty() {
    this.cdr.markForCheck();
  }
  setPage(pageIndex) {
    this.emitEvent.set(true);
    this.pageIndex.set(pageIndex);
  }
  first(opts = undefined) {
    this.emitEvent.set(!!opts?.emitEvent);
    this.pageIndex.set(0);
  }
  static {
    this.ɵfac = function LfPaginator_Factory(t) {
      return new (t || LfPaginator)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfPaginator,
      selectors: [["lf-paginator"]],
      inputs: {
        length: "length",
        currentPage: "currentPage"
      },
      outputs: {
        pageChange: "pageChange"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 9,
      vars: 5,
      consts: [[1, "paginator"], ["class", "page-info"], [1, "pages"], ["type", "button", "class", "page first arrow-btn", 3, "ngClass"], ["type", "button", "class", "page prev arrow-btn", 3, "ngClass"], ["type", "button", "class", "page next arrow-btn", 3, "ngClass"], ["type", "button", "class", "page last arrow-btn", 3, "ngClass"], [1, "page-info"], ["type", "button", 1, "page", "first", "arrow-btn", 3, "ngClass", "click"], ["name", "caret_left_double"], ["type", "button", 1, "page", "prev", "arrow-btn", 3, "ngClass", "click"], ["name", "caret_left"], ["class", "page active"], [1, "page", "active", 3, "click"], ["type", "button", 1, "page", "page-btn", 3, "click"], ["type", "button", 1, "page", "next", "arrow-btn", 3, "ngClass", "click"], ["name", "caret_right"], ["type", "button", 1, "page", "last", "arrow-btn", 3, "ngClass", "click"], ["name", "caret_right_double"]],
      template: function LfPaginator_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, LfPaginator_Conditional_1_Template, 2, 4, "div", 1);
          i0.ɵɵelementStart(2, "div", 2);
          i0.ɵɵtemplate(3, LfPaginator_Conditional_3_Template, 2, 3, "button", 3)(4, LfPaginator_Conditional_4_Template, 2, 3, "button", 4);
          i0.ɵɵrepeaterCreate(5, LfPaginator_For_6_Template, 2, 1, null, null, i0.ɵɵrepeaterTrackByIdentity);
          i0.ɵɵtemplate(7, LfPaginator_Conditional_7_Template, 2, 3, "button", 5)(8, LfPaginator_Conditional_8_Template, 2, 3, "button", 6);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵconditional(1, !ctx.config.hidePageInfo ? 1 : -1);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(3, ctx.config.showFirstLastButtons ? 3 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(4, ctx.config.showPrevNextButtons ? 4 : -1);
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.buttonsList());
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(7, ctx.config.showPrevNextButtons ? 7 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(8, ctx.config.showFirstLastButtons ? 8 : -1);
        }
      },
      dependencies: [NgClass, LfIconComponent],
      styles: [".paginator[_ngcontent-%COMP%]{display:flex;align-items:center;gap:var(--lf-paginator-info-gap)}.page-info[_ngcontent-%COMP%]{font:var(--lf-paginator-info-font);color:var(--lf-text-300)}.pages[_ngcontent-%COMP%]{display:flex;gap:var(--lf-paginator-buttons-gap)}.page[_ngcontent-%COMP%]{display:flex;align-items:center;justify-content:center;min-width:var(--lf-paginator-button-size);height:var(--lf-paginator-button-size);font:var(--lf-paginator-button-font);color:var(--lf-text-500);border:var(--lf-paginator-button-border-size) solid transparent;border-radius:var(--lf-paginator-button-border-radius);background:transparent;outline:none;transition-duration:var(--lf-transition-duration);transition-property:color,background-color,border-color;-webkit-user-select:none;user-select:none}.page.arrow-btn[_ngcontent-%COMP%]{font-size:var(--lf-paginator-button-icon-size);border-color:var(--lf-main-300);background:var(--lf-bg-100);color:var(--lf-main-700);cursor:pointer}.page.arrow-btn[_ngcontent-%COMP%]:hover, .page.arrow-btn[_ngcontent-%COMP%]:focus-visible{background:var(--lf-main-100);color:var(--lf-main-900)}.page.page-btn[_ngcontent-%COMP%]{cursor:pointer}.page.page-btn[_ngcontent-%COMP%]:hover, .page.page-btn[_ngcontent-%COMP%]:focus-visible{border-color:var(--lf-main-300);background:var(--lf-bg-100);color:var(--lf-text-500)}.page.active[_ngcontent-%COMP%]{color:var(--lf-brand-text);background-color:var(--lf-brand-400);border-color:var(--lf-brand-400)}.page.disabled[_ngcontent-%COMP%]{pointer-events:none}.page.disabled.first[_ngcontent-%COMP%], .page.disabled.prev[_ngcontent-%COMP%]{visibility:hidden}.page.disabled.next[_ngcontent-%COMP%], .page.disabled.last[_ngcontent-%COMP%]{display:none}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfPaginator, [{
    type: Component,
    args: [{
      selector: 'lf-paginator',
      standalone: true,
      imports: [NgClass, LfIconComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"paginator\">\n  @if (!config.hidePageInfo) {\n    <div class=\"page-info\">\n      {{ intl.rows }} {{ pageInfo() }} {{ intl.of }} {{ totalCount() }}\n    </div>\n  }\n  <div class=\"pages\">\n    @if (config.showFirstLastButtons) {\n      <button type=\"button\" class=\"page first arrow-btn\"\n           [ngClass]=\"{disabled: prevButtonDisabled()}\"\n           (click)=\"!prevButtonDisabled() && setPage(0)\">\n        <lf-icon name=\"caret_left_double\"></lf-icon>\n      </button>\n    }\n    @if (config.showPrevNextButtons) {\n      <button type=\"button\" class=\"page prev arrow-btn\"\n           [ngClass]=\"{disabled: prevButtonDisabled()}\"\n           (click)=\"!prevButtonDisabled() && setPage(pageIndex() - 1)\">\n        <lf-icon name=\"caret_left\"></lf-icon>\n      </button>\n    }\n    @for (i of buttonsList(); track i) {\n      @if (i === pageIndex()) {\n        <div class=\"page active\" (click)=\"setPage(i)\">{{ i + 1 }}</div>\n      } @else {\n        <button type=\"button\" class=\"page page-btn\" (click)=\"setPage(i)\">{{ i + 1 }}</button>\n      }\n    }\n    @if (config.showPrevNextButtons) {\n      <button type=\"button\" class=\"page next arrow-btn\"\n           [ngClass]=\"{disabled: nextButtonDisabled()}\"\n           (click)=\"!nextButtonDisabled() && setPage(pageIndex() + 1)\">\n        <lf-icon name=\"caret_right\"></lf-icon>\n      </button>\n    }\n    @if (config.showFirstLastButtons) {\n      <button type=\"button\" class=\"page last arrow-btn\"\n           [ngClass]=\"{disabled: nextButtonDisabled()}\"\n           (click)=\"!nextButtonDisabled() && setPage(lastPageIndex())\">\n        <lf-icon name=\"caret_right_double\"></lf-icon>\n      </button>\n    }\n  </div>\n</div>\n",
      styles: [".paginator{display:flex;align-items:center;gap:var(--lf-paginator-info-gap)}.page-info{font:var(--lf-paginator-info-font);color:var(--lf-text-300)}.pages{display:flex;gap:var(--lf-paginator-buttons-gap)}.page{display:flex;align-items:center;justify-content:center;min-width:var(--lf-paginator-button-size);height:var(--lf-paginator-button-size);font:var(--lf-paginator-button-font);color:var(--lf-text-500);border:var(--lf-paginator-button-border-size) solid transparent;border-radius:var(--lf-paginator-button-border-radius);background:transparent;outline:none;transition-duration:var(--lf-transition-duration);transition-property:color,background-color,border-color;-webkit-user-select:none;user-select:none}.page.arrow-btn{font-size:var(--lf-paginator-button-icon-size);border-color:var(--lf-main-300);background:var(--lf-bg-100);color:var(--lf-main-700);cursor:pointer}.page.arrow-btn:hover,.page.arrow-btn:focus-visible{background:var(--lf-main-100);color:var(--lf-main-900)}.page.page-btn{cursor:pointer}.page.page-btn:hover,.page.page-btn:focus-visible{border-color:var(--lf-main-300);background:var(--lf-bg-100);color:var(--lf-text-500)}.page.active{color:var(--lf-brand-text);background-color:var(--lf-brand-400);border-color:var(--lf-brand-400)}.page.disabled{pointer-events:none}.page.disabled.first,.page.disabled.prev{visibility:hidden}.page.disabled.next,.page.disabled.last{display:none}\n"]
    }]
  }], () => [], {
    pageChange: [{
      type: Output
    }],
    length: [{
      type: Input
    }],
    currentPage: [{
      type: Input
    }]
  });
})();

/*
 * Public API Surface of paginator
 */

/**
 * Generated bundle index. Do not edit.
 */

export { LF_DEFAULT_PAGINATOR_CONFIG, LF_PAGINATOR_CONFIG, LfDefaultPaginatorIntl, LfPaginator, LfPaginatorIntlService };
